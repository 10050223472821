import React, { useState, useEffect } from "react";
import { useForm,Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
  faPercentage,
  faPercent,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import {
  create_challan_type,
    delete_challan_type,
  get_challan_type,
} from "../../../api";
import {
  calMaxPage,
} from "../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import EditChallanType from "./EditChallanType";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    typename: yup.string().required("Name is required!"),
    cType: yup
    .array()
    .required("Please select challan type !")
    .min(1, "Please select challan type !"),
    idprefix: yup
    .string()
    .required("ID prefix is required !")
    .matches(/^[a-zA-Z]*$/, "Enter valid id prefix !"),
  })
  .required();

function ChallanType() {
  const [sameLocation, setSameLocation] = useState(false);

  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [sea_val, setSea_val] = useState(false);
  const [filterConfig, setFilterConfig] = useState({ searchVal: [] });

  const [constLabel, setConstLabel] = useState({});

  const [typChallan, setTypChallan] = useState({
    data: [
      { id: 1, name: "Inward" },
      { id: 2, name: "Outward" },
    ],
    state: false,
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchChallanTypeList(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: [] });
  }

  function fetchChallanTypeList(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.searchVal.length > 0) {
      // payload.productName_list = filterConfig.productName;
      payload.challanType = filterConfig.searchVal[0];
      if (
        filterConfig.searchVal[0].length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;

        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
    }

    get_challan_type(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        if (
          filterConfig.searchVal.length > 0 &&
          filterConfig.searchVal[0].length > 0
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }
        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        typename: data.typename.trim(),
        cType: data.cType?.[0]?.name,
        idprefix: data.idprefix,
        outoperation: false,
        sameLocation: sameLocation,
        wip: false,
        remark: data?.remark?data.remark:null,
      };
      
      // console.log("reqpayload",reqPayload)
      // return 

      create_challan_type(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Challan type created successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            fetchChallanTypeList(page, entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // Deleting Challan Type
  const delChallan_Type = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        delete_challan_type(payload).then(
          (res) => {
            toast.success("Challan type deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchChallanTypeList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(1);
            } else {
              fetchChallanTypeList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>
              {"Add Challan Type"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
            <div className="form-group">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    { "Challan Type"}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name="cType"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                          typChallan.data !== undefined ? typChallan.data : []
                        }
                        isLoading={typChallan.state}
                        placeholder="Select challan type..."
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.cType ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">{errors.cType?.message}</span>
                </div>
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 bold"
                >
                  { "Name"}
                  <span className="text-danger"> *</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.typename ? "form-control  is-invalid" : "form-control "
                    }
                    id="typename"
                    name="typename"
                    placeholder="Enter challan type"
                    {...register("typename")}
                  />
                  <span className="text-danger err-msg">
                    {errors.typename?.message}
                  </span>
                </div>
              </div>
              <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    { "ID Prefix"}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.idprefix
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="idprefix"
                    name="idprefix"
                    placeholder="Enter id prefix"
                    {...register("idprefix")}
                  />
                  <span className="text-danger err-msg">
                    {errors.idprefix?.message}
                  </span>
                </div>
                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    { "Same Location"}
                  </label>
                  <div className="form-control">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="sameLocation"
                      name="sameLocation"
                      onChange={() => setSameLocation(!sameLocation)}
                      checked={sameLocation}
                    />
                    <label className="form-check-label form_label ms-2">
                      {" "}
                      {" Is Same location Required ?"}
                    </label>
                  </div>
                </div>  
                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    { "Remark"}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="remark"
                    name="remark"
                    placeholder="Enter remark"
                    {...register("remark")}
                  />
                </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>

      
          <div id="mySidebar" className="customsidebar">
            <a className="closebtn" onClick={() => closeNav()}>
              ×
            </a>

            <div className=" content">
              <div>
                <label
                  className="filterLabel mb-1 bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  placeholder="Search by type"
                  value={
                    filterConfig.searchVal.length > 0
                      ? filterConfig.searchVal
                      : ""
                  }
                  onChange={(val) => {
                    const inputValue = val.target.value; // Trim leading and trailing whitespaces

                    if (inputValue.trim() !== "") {
                      setFilterConfig({
                        ...filterConfig,
                        searchVal: [inputValue],
                      });
                    } else {
                      // setFilterConfig({ ...filterConfig, searchVal: [] });
                    }
                  }}
                />
              </div>
              <div className="my-3 d-flex justify-content-end">
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-sm btn-yellow "
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
      
        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="page-heading">{"Challan Type"}</h1>
            <div>
              {/* <span
                className="cursor_pointer px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                { "Filter Menu"}
              </span> */}

             
                <button
                  className="btn btn-pink px-4"
                  onClick={() => {
                    setVisible(true);
                    reset();
                  }}
                >
                  <FontAwesomeIcon
                    style={{ color: "#fff" }}
                    icon={faPlus}
                    size="sm"
                  />{" "}
                  { "Add Challan Type"}
                </button>
              
            </div>
          </div>
          <div className="card border-0 mt-4">
            <div className="card-body">
              {/* <div className="d-flex justify-content-between mt-1">
                <p className="mb-0">
                  Show
                  <select
                    className="mx-1"
                    defaultValue={"10"}
                    onChange={(e) => {
                      setEntriesPerPage(e.target.value);
                      fetchChallanTypeList(1, e.target.value);
                    }}
                  >
                    <option value={"10"}>10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Entries
                </p>
                <div className="dropdown">
                  <div
                    className="settignIcon_custom_nc d-flex justify-content-center align-items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon
                      style={{ color: "#fff" }}
                      icon={faCog}
                      size="sm"
                    />
                  </div>

                  <ul className="dropdown-menu px-2">
                    {showLabel !== undefined && showLabel.length > 0
                      ? showLabel.map((val, ind, arr) => (
                          <li key={ind}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                                onChange={() => handleClick(ind)}
                                value=""
                                checked={val.flag}
                              />
                              <label
                                className=""
                                style={{ fontSize: "13px" }}
                                htmlFor="flexCheckDefault"
                              >
                                {val.label}
                              </label>
                            </div>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div> */}
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table
                        className="table table-striped table-sm"
                        width={"100%"}
                      >
                        <thead className="table-grey">
                          <tr className="">
                            <th scope="col" width="15%">
                              {"Sr.No"}
                            </th>
                            
                            <th  scope="col">
                                  { "Name"}
                                </th>
                                <th  scope="col">
                                  { "Challan Type"}
                                </th>
                                <th  scope="col">
                                  { "ID Prefix"}
                                </th>
                                <th  scope="col">
                                  { "Same Location"}
                                </th>
                                <th  scope="col">
                                  { "Remark"}
                                </th>
                            
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {dataList.data?.map((val, i) => (
                            <tr key={i} className="">
                              {/* <td>{val.NCReasonId}</td> */}
                              <td>{i + parseInt(srNo) + 1}</td>
                            
                                  <td >
                                    {val.typename != "" && val.typename !== null
                                      ? val.typename
                                      : "NA"}
                                  </td>

                                  <td >
                                    {val.cType != "" && val.cType !== null
                                      ? val.cType
                                      : "NA"}
                                  </td>
                                  <td >
                                    {val.idprefix != "" && val.idprefix !== null
                                      ? val.idprefix
                                      : "NA"}
                                  </td>
                                  
                                  <td >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          style={{ width: "20px", height: "20px" }}
                                          checked={
                                            (val.sameLocation !== undefined &&
                                              val.sameLocation !== null &&
                                              val.sameLocation !== false) ? true : false
                                          }
                                          disabled
                                        />
                                      </td>

                                      <td >
                                    {val.remark != "" && val.remark !== null
                                      ? val.remark
                                      : "NA"}
                                  </td>
                              <td>
                                
                                  <EditChallanType
                                    data={val}
                                    constLabel={constLabel}
                                    fetchChallanTypeList={fetchChallanTypeList}
                                    page={page}
                                    entriesPerPage={entriesPerPage}
                                  />
                               

                               
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip" + i}
                                      place="top"
                                    />
                                    <div
                                      data-tooltip-id={"delete-tooltip" + i}
                                      data-tooltip-content={
                                        "Delete Challan Type"
                                      }
                                      className="deleteIcon me-2"
                                      onClick={() => delChallan_Type(val)}
                                    >
                                      <Trash2 color="#FFFFFF" size={18} />
                                    </div>
                                  </>
                                
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination>
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchChallanTypeList(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchChallanTypeList(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Challan Type not found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ChallanType;
