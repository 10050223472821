import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { update_challan_type } from "../../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    typename: yup.string().required("Name is required !"),
    cType: yup
      .array()
      .required("Please select challan type !")
      .min(1, "Please select challan type !"),
    idprefix: yup
      .string()
      .required("ID prefix is required !")
      .matches(/^[a-zA-Z]*$/, "Enter valid id prefix !"),
  })
  .required();

function EditChallanType(props) {
  const [visible, setVisible] = useState(false);
  const [sameLocation, setSameLocation] = useState(props.data.sameLocation);

  const [typChallan, setTypChallan] = useState({
    data: [
      { id: 1, name: "Inward" },
      { id: 2, name: "Outward" },
    ],
    state: false,
  });
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        typename: data.typename.trim(),
        cType: data.cType?.[0]?.name,
        idprefix: data.idprefix,
        outoperation: false,
        sameLocation: sameLocation,
        wip: false,
        remark: data?.remark ? data.remark : null,
      };

      update_challan_type(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Challan type updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchChallanTypeList(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={"Edit Challan Type"}
        className="editIcon me-2"
      >
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
            reset();
          }}
        />
      </div>
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Update Challan Type"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {"Challan Type"}
                <span className="text-danger"> *</span>
              </label>
              <Controller
                name="cType"
                control={control}
                defaultValue={props.data.cType?[{name:props.data.cType}]:[]}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    multiple={false}
                    options={
                      typChallan.data !== undefined ? typChallan.data : []
                    }
                    defaultSelected={props.data.cType?[{name:props.data.cType}]:[]}
                    isLoading={typChallan.state}
                    placeholder="Select challan type..."
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.cType ? true : false}
                  />
                )}
              />
              <span className="text-danger err-msg">
                {errors.cType?.message}
              </span>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {"Name"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.typename
                      ? "form-control  is-invalid"
                      : "form-control "
                  }
                  id="typename"
                  name="typename"
                  placeholder="Enter challan type"
                  defaultValue={props.data?.typename}
                  {...register("typename")}
                />
                <span className="text-danger err-msg">
                  {errors.typename?.message}
                </span>
              </div>
            </div>
            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {"ID Prefix"}
                <span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className={
                  errors.idprefix ? "form-control  is-invalid" : "form-control"
                }
                id="idprefix"
                name="idprefix"
                placeholder="Enter id prefix"
                defaultValue={props.data.idprefix?props.data.idprefix:null}
                {...register("idprefix")}
              />
              <span className="text-danger err-msg">
                {errors.idprefix?.message}
              </span>
            </div>
            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {"Same Location"}
              </label>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="sameLocation"
                  name="sameLocation"
                  onChange={() => setSameLocation(!sameLocation)}
                  checked={sameLocation}
                />
                <label className="form-check-label form_label ms-2">
                  {" "}
                  {" Is Same location Required ?"}
                </label>
              </div>
            </div>
            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {"Remark"}
              </label>
              <input
                type="text"
                className="form-control"
                id="remark"
                name="remark"
                defaultValue={props.data.remark?props.data.remark:null}
                placeholder="Enter remark"
                {...register("remark")}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditChallanType;
