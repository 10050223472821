import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
  faPercentage,
  faPercent,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { createSOStatus, deleteSOStatus, getSOStatus } from "../../../api";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import EditSalesStatus from "./EditSalesStatus";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Status name is required !"),
    description: yup.string().required("Description is required !"),
  })
  .required();

function SalesStatus() {
  const [showLabel, setShowLabel] = useState([]);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [sea_val, setSea_val] = useState(false);
  const [filterConfig, setFilterConfig] = useState({ searchVal: [] });

  const [constLabel, setConstLabel] = useState({});

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchSalesOrderStatusList(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: [] });
  }

  function fetchSalesOrderStatusList(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.searchVal.length > 0) {
      // payload.productName_list = filterConfig.productName;
      payload.searchVal = filterConfig.searchVal[0];
      if (
        filterConfig.searchVal[0].length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;

        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
    }

    getSOStatus(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        if (
          filterConfig.searchVal.length > 0 &&
          filterConfig.searchVal[0].length > 0
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }
        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        status_name: data.name.trim(),
        status_description: data.description.trim(),
      };

      createSOStatus(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Sales order status created successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            fetchSalesOrderStatusList(page, entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // Deleting Sales Order Status
  const delSalesOrderStatus = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deleteSOStatus(payload).then(
          (res) => {
            toast.success("Sales order status deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchSalesOrderStatusList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(1);
            } else {
              fetchSalesOrderStatusList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{"Add Sales Order Status"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 bold"
                >
                  {"Sales Order Status"}
                  <span className="text-danger"> *</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.name ? "form-control  is-invalid" : "form-control "
                    }
                    id="name"
                    name="name"
                    placeholder="Enter sales order status"
                    {...register("name")}
                  />
                  <span className="text-danger err-msg">
                    {errors.name?.message}
                  </span>
                </div>
              </div>
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 bold"
                >
                  {"Description"}
                  <span className="text-danger"> *</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.name ? "form-control  is-invalid" : "form-control "
                    }
                    id="description"
                    name="description"
                    placeholder="Enter description"
                    {...register("description")}
                  />
                  <span className="text-danger err-msg">
                    {errors.description?.message}
                  </span>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {"Save"}
            </button>
          </Modal.Footer>
        </Modal>

        <div id="mySidebar" className="customsidebar">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel mb-1 bold"
                style={{ fontSize: "14px" }}
              >
                {"Search"}
              </label>
              <input
                className="form-control form-control-sm"
                type="text"
                placeholder="Search by type"
                value={
                  filterConfig.searchVal.length > 0
                    ? filterConfig.searchVal
                    : ""
                }
                onChange={(val) => {
                  const inputValue = val.target.value; // Trim leading and trailing whitespaces

                  if (inputValue.trim() !== "") {
                    setFilterConfig({
                      ...filterConfig,
                      searchVal: [inputValue],
                    });
                  } else {
                    // setFilterConfig({ ...filterConfig, searchVal: [] });
                  }
                }}
              />
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {"Clear Filter"}
              </button>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="page-heading">{"Sales Order Status"}</h1>
            <div>
              <span
                className="cursor_pointer px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                {"Filter Menu"}
              </span>

              <button
                className="btn btn-pink px-4"
                onClick={() => {
                  setVisible(true);
                  reset();
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {"Add Sales Order Status"}
              </button>
            </div>
          </div>
          <div className="card border-0 mt-4">
            <div className="card-body">
              {/* <div className="d-flex justify-content-between mt-1">
                <p className="mb-0">
                  Show
                  <select
                    className="mx-1"
                    defaultValue={"10"}
                    onChange={(e) => {
                      setEntriesPerPage(e.target.value);
                      fetchSalesOrderStatusList(1, e.target.value);
                    }}
                  >
                    <option value={"10"}>10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Entries
                </p>
                <div className="dropdown">
                  <div
                    className="settignIcon_custom_nc d-flex justify-content-center align-items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon
                      style={{ color: "#fff" }}
                      icon={faCog}
                      size="sm"
                    />
                  </div>

                  <ul className="dropdown-menu px-2">
                    {showLabel !== undefined && showLabel.length > 0
                      ? showLabel.map((val, ind, arr) => (
                          <li key={ind}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                                onChange={() => handleClick(ind)}
                                value=""
                                checked={val.flag}
                              />
                              <label
                                className=""
                                style={{ fontSize: "13px" }}
                                htmlFor="flexCheckDefault"
                              >
                                {val.label}
                              </label>
                            </div>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div> */}
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table
                        className="table table-striped table-sm"
                        width={"100%"}
                      >
                        <thead className="table-grey">
                          <tr className="">
                            <th scope="col" width="15%">
                              {"Sr.No"}
                            </th>

                            <th scope="col">{"Sales Order Status"}</th>

                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {dataList.data?.map((val, i) => (
                            <tr key={i} className="">
                              {/* <td>{val.NCReasonId}</td> */}
                              <td>{i + parseInt(srNo) + 1}</td>

                              <td>
                                {val.status_name != "" &&
                                val.status_name !== null
                                  ? val.status_name
                                  : "NA"}
                              </td>

                              <td>
                                <EditSalesStatus
                                  data={val}
                                  constLabel={constLabel}
                                  fetchSalesOrderStatusList={
                                    fetchSalesOrderStatusList
                                  }
                                  page={page}
                                  entriesPerPage={entriesPerPage}
                                />

                                <>
                                  <Tooltip
                                    id={"delete-tooltip" + i}
                                    place="top"
                                  />
                                  <div
                                    data-tooltip-id={"delete-tooltip" + i}
                                    data-tooltip-content={
                                      "Delete Sales Order Status"
                                    }
                                    className="deleteIcon me-2"
                                    onClick={() => delSalesOrderStatus(val)}
                                  >
                                    <Trash2 color="#FFFFFF" size={18} />
                                  </div>
                                </>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination>
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchSalesOrderStatusList(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchSalesOrderStatusList(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Sales Order Status not found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SalesStatus;
