import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";

function MastersMenu() {
  let navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="mb-4 master_screen">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {"Masters"}
            </p>
          </div>
          <div className="card-body" id="ccard">
            

              <div className="row">
             
            

            
                <div className="col-md-3 mt-2">
                  <Link to="/gstSlab" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">GST Slab</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>

                </div>
                <div className="col-md-3 mt-2">
                  <Link to="/companyType" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Company Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>

                </div>
                <div className="col-md-3 mt-2">
                  <Link to="/orgnisationType" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Organisation Type</Card.Title>

                      </Card.Body>
                    </Card>
                  </Link>

                </div>
                <div className="col-md-3 mt-2">
                  <Link to="/storeType" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Store Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>

                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/gstTreatment" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">{"GST Registration Type"}</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>

                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/task-category" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Task Category</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>

                </div>

          
           
                <div className="col-md-3 mt-2">
                  <Link to="/statusMaster" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Status</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>

                </div>

               


                <div className="col-md-3 mt-2">
                  <Link to="/document_type" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Document Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/inspectionType" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Inspection Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
                <div className="col-md-3 mt-2">
                  <Link to="/hsnmaster" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">HSN Master</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/adjustment-reason" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Adjustment Reason</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
                <div className="col-md-3 mt-2">
                  <Link to="/product-type" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Product Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/vendor-type" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Vendor Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/industry-type" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Industry Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/break-code" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Break Code</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/location-type" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Location Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
                <div className="col-md-3 mt-2">
                  <Link to="/challan-type" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Challan Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/supplier-type" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Supplier Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/customer-type" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Customer Type</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-md-3 mt-2">
                  <Link to="/sales-order-status" className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">Sales Order Status</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

             

           

              </div>

            
            
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default MastersMenu;
